<template>
  <div class="form-stepper form-stepper-feed">
    <v-form 
      ref="form"
      v-model="valid"
      class="form pa-6"
      @submit.prevent
    >
      <p>{{ $t('FEED.INTRODUCTION_TEXT') }}</p>
      <div class="pa-0">
        <v-row>
          <v-col cols="8">
            <v-text-field
              dense
              v-model="title"
              :rules="[$rules.required, $rules.maxlength(255)]"
              :label="$t('INPUT.TITLE')"
              :placeholder="$t('INPUT.TITLE')"
              @keyup.enter="validate"
              outlined
              required
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="8">
            <v-checkbox
              class="mt-0 pt-0"
              v-model="auto_refresh"
              :label="$t('INPUT.AUTOREFRESH')"
              :placeholder="$t('INPUT.AUTOREFRESH')"
              outlined
            />
            <v-checkbox
                class="mt-0 pt-0"
                v-model="legacy_html_to_md"
                :label="$t('FEED.FIELD_LEGACY_HTML_TO_MD')"
                :placeholder="$t('FEED.FIELD_LEGACY_HTML_TO_MD')"
                outlined
            />
            <v-text-field type="number" min="0" max="100"
                class="mt-0 pt-0"
                v-model="max_churn_percent"
                :label="$t('FEED.FIELD_MAX_CHURN_PERCENT')"
                :placeholder="$t('FEED.FIELD_MAX_CHURN_PERCENT')"
                outlined
            />
          </v-col>
        </v-row>
      </div>

    </v-form>

    <v-divider />
    <v-toolbar 
      color="transparent"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer/>
      <v-btn 
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t('COMMON.CONTINUE') }}</strong>
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>

    </v-toolbar>

  </div>
</template>

<script>
export default {
  name: 'FormFeedStepperIntro',
  data: () => ({
    loading: false,
    valid: false,
    auto_refresh: false,
    legacy_html_to_md: false,
    max_churn_percent: 50,
    title: null,
  }),
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    values (n) {
      this.init()
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      Object.keys(this.values)
        .forEach((key) => {
          if (typeof this.values[key] !== 'undefined' && typeof this[key] !== 'undefined') {
            this[key] = this.values[key]
          }
        })
    },
    entered() {
      alert('Enter')
    },
    validate () {
      if (!this.$refs.form.validate()) {
        this.$emit('validation', false)
        return
      }

      const data = {}
      data.title = this.title || undefined
      data.auto_refresh = this.auto_refresh || undefined
      data.legacy_html_to_md = this.legacy_html_to_md || undefined
      data.max_churn_percent = this.max_churn_percent || undefined

      this.$emit('childData', data)
      this.$emit('validation', true)
      this.$emit('next')
    }
  },
}
</script>

<style scoped>

</style>
